<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo" style="display:block">
        <b-img
          v-if="checkCoca()"
          fluid
          src="@/assets/images/logo/coca_logo.png"
          alt="Logo"
          style="width: 15%"
        />
        <b-img
          v-else-if="checkOutHome()"
          fluid
          src="@/assets/images/logo/ooh.avif"
          alt="Logo"
          style="width: 7%"
        />
        <b-img
          v-else
          fluid
          class="logo-bm"
          src="@/assets/images/logo/Logo_BrandMe_Sponsorship.avif"
          alt="Logo"
        />
      </b-link>
      <!-- /Brand logo-->
      <!-- Left Text-->
      <!-- TODO: Backgroud para outofhome -->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center login-data-form"
        style="background-size: cover"
        :style="bgurl"
      >
        <div class="w-100 h-100 d-lg-flex align-items-center justify-content-center d-none d-md-block">
          <video class="video-bg" preload="auto" loop="" autoplay="" muted="" playsinline="" v-if="checkOutHome()">
            <source src="https://fintual.outofhome.mx/wp-content/uploads/2022/02/out-of-home-portafolio.mp4" type="video/webm">
          </video>
          <b-link v-else href="https://www.instagram.com/analaura_gonzalez/" target="_blank">
            <div class="avatar-position d-flex align-items-center">
              <b-avatar
                @click="'https://www.instagram.com/analaura_gonzalez/'"
                src="@/assets/images/logo/ana_avatar.avif"
                class="avatar-login"
                size="4em"
              />
              <span class="username-logo">Ana Laura González</span>
            </div>
          </b-link>
          
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Login-->
      <b-col
        lg="6"
        class="col-12 d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          class="col-12 col-md-7 mx-auto p-md-0 mt-2 mt-md-0"
        >
          <b-card-title
            class="mb-1 font-weight-bold text-center avenir-heavy font-32 text-black"
            title-tag="h2"
          >
            <div v-if="checkOutHome()">{{ $t('login.title_ooh') }}</div>
            <div v-else>{{ $t('login.title') }}</div>
          </b-card-title>
          <b-card-text class="mb-2 text-center avenir-medium">
            <div v-if="checkOutHome()">{{ $t('login.text_ooh') }}</div>
            <div v-else>{{ $t('login.text') }}</div>
          </b-card-text>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center" v-if="!checkOutHome()">
            <GoogleLogin class="google-btn" :params="params" :onSuccess="onSuccess" :onFailure="onFailure">
              <span class="google-icon" :style="`background: url(${require('@/assets/images/google/google.svg')}) transparent 5px 50% no-repeat;`"></span>
              <span class="google-text">{{ $t('login.googleLogin') }}</span>
            </GoogleLogin>
          </div>

          <!-- divider -->
          <div class="divider my-2" v-if="!checkOutHome()">
            <div class="divider-text">
              {{ $t('login.divider') }} 
            </div>
          </div>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group>
                <label for="login-email" class="avenir-medium">{{ $t('login.email') }}</label>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('login.email')"
                  :vid="$t('login.email')"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                   :placeholder="$t('login.email')"
                   trim
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password" class="avenir-medium">{{ $t('login.password') }}</label>
                  <b-link :to="{name:'auth-forgot-password'}" class="avenir-medium">
                    <small>{{ $t('login.forgotPassword') }}</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('login.password')"
                  :vid="$t('register.password')"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                     :placeholder="$t('register.password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                  class="avenir-medium"
                >
                  {{ $t('login.rememberPassword') }}
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                class="blue-button"
                variant="blue-button"
                block
                :disabled="invalid"
              >
                {{ $t('login.button') }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2 avenir-medium">
            <span v-if="checkOutHome()">{{ $t('login.new_ooh') }}</span>
            <span v-else>{{ $t('login.new') }}</span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;{{ $t('login.create') }}</span>
            </b-link>
          </b-card-text>
          <b-card-text class="text-center" v-if="!checkOutHome()">
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip, BAvatar
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import service from '@/services/others'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import abilities from '@/libs/acl/abilities'
import utils from '@/libs/utils'
import useAppConfig from '@core/app-config/useAppConfig'


export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    BAvatar,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    GoogleLogin: () => import('vue-google-login')
  },
  mixins: [togglePasswordVisibility],
  data () {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      rutaDominio: window.location.host,
      bgurl: this.backLogin(window.location.host),
      // validation rules
      required,
      email,
      readAbility: [],
      params: {
        client_id: '41988944628-epj92qelccpcub5snlh6p08l4mnelmql.apps.googleusercontent.com'
      },
      renderParams: {
        width: 480,
        height: 50,
        longtitle: false,
        lang: 'es'
      },
    }
  },
  setup () {
    const { user } = useAppConfig()

    return {
      user
    }
  },
  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl () {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  created () {
    localize(this.$i18n.locale)
  },
  methods: {
    showErrorLogin(title) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        }
      })
    },
    login () {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
            is_app: true
          })
            .then(response => response.data.response)
            .then(response => {
              if (this.checkOutHome()) {
                if (response.user.main_group.name !== 'realty') return this.showErrorLogin(this.$t('campaigns.error_status'))
                this.setUser(response)
              } else {
                if (response.user.main_group && response.user.main_group.name === 'realty') return this.showErrorLogin(this.$t('campaigns.error_status'))
                this.setUser(response)
              }
            })
            .catch(error => {
              const code = error.response && error.response.data && error.response.data.response.code ? error.response.data.response.code : null
              if (code === 'accounts.emailDoesNotExists') {
                this.showErrorLogin(this.$t('login.mailNotExists'))
              } else if (code === 'accounts.inactiveAccount') {
                this.showErrorLogin(this.$t('login.inactiveAccount'))
              } else if (code === 'accounts.BadLogin') {
                this.showErrorLogin(this.$t('login.badPassword'))
              } else {
                this.showErrorLogin(this.$t('campaigns.error_status'))
              }
            })
        }
      })
    },
    backLogin (ruta) {
      if (ruta.includes('coca')) return `background-image: url(${require("@/assets/images/coca1.png")})`
      else if (ruta.includes('outofhome')) return ''
      return `background-image: url(${require("@/assets/images/logo/logo_ana.avif")})`
    },
    onSuccess (googleUser) {
      const keys = Object.keys(googleUser)
      let index = ''
      keys.forEach(key => {
        if (googleUser[key].id_token) index = googleUser[key]
      })
      service.googleLogin({token: index.id_token})
        .then(response => {
          this.setUser(response)
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('login.errorGoogle'),
              text: this.$t('login.textGoogle'),
              icon: 'AlertCircleIcon',
              variant: 'warning'
            }
          },
          {
            position: 'top-left'
          })
        })
    },
    onFailure (error) {
      console.log(error)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('login.errorGoogle'),
          icon: 'AlertCircleIcon',
          variant: 'warning'
        }
      },
      {
        position: 'top-left'
      })
    },
    setUser (data) {
      //const vue = this
      useJwt.setToken(data.access_token)
      useJwt.setRefreshToken(data.refresh_token)
      utils.setPermissions(data.user);
      this.readAbility = abilities.getAbilitie(data.user)

      const show_connect_message = data.user.main_group && data.user.main_group.name === 'creator'
      localStorage.setItem('show_connect_message', show_connect_message)

      utils.setuserData(data, this.readAbility)
      this.$ability.update(this.readAbility)
      this.$store.commit('appConfig/UPDATE_IS_VERIFIED', data.user.is_verified)
      this.$router.push(getHomeRouteForLoggedInUser(data.user))
        .then()

      this.$root.$emit('connect_ws')
    },
    checkCoca() {
      return this.rutaDominio.includes('coca')
    },
    checkOutHome() {
      return this.rutaDominio.includes('outofhome')
    }
  }
}
</script>
<style>
@media(max-width: 500px) {
  .auth-inner {
    display: block !important;
  }
  .login-data-form {
    display: block !important;
    margin-top: 1em !important;
  }
}
</style>
<style lang="scss">
.font-32 {
  font-size: 32px
}
.auth-login-form {
  label {
    font-size: 14px
  }
}
@import '@core/scss/vue/pages/page-auth.scss';
</style>
